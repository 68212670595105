/* Estilos base */
body {
  font-size: 16px;
  line-height: 1.5;
}

/* Contenedor principal */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .container {
    padding: 0 10px;
  }

  /* Ajustar el tamaño de los botones para móviles */
  button {
    padding: 10px 15px;
    font-size: 14px;
  }

  /* Hacer que las tablas sean scrollables horizontalmente en móviles */
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* Estilos para tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    padding: 0 20px;
  }
}

/* Ajustes para formularios responsivos */
form {
  display: flex;
  flex-direction: column;
}

input, select, textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
}

/* Grid system básico */
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.col {
  flex: 1;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
