.react-big-calendar {
  height: 100%;
  font-family: Arial, sans-serif;
}

.react-big-calendar .rbc-header {
  padding: 10px 3px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.react-big-calendar .rbc-month-view,
.react-big-calendar .rbc-time-view {
  border: 1px solid #ddd;
}

.react-big-calendar .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.react-big-calendar .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}

.react-big-calendar .rbc-event {
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 1px;
}

.react-big-calendar .rbc-month-view .rbc-event {
  font-size: 0.75em;
  padding: 1px 3px;
}

.react-big-calendar .rbc-month-row {
  overflow: visible;
}

.react-big-calendar .rbc-row-content {
  z-index: 1;
}

.react-big-calendar .rbc-today {
  background-color: #e8f4ff;
}

.react-big-calendar .rbc-date-cell {
  text-align: center;
  padding: 5px;
}

.react-big-calendar .rbc-date-cell > a {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.react-big-calendar .rbc-off-range-bg {
  background-color: #f5f5f5;
}

.react-big-calendar .rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.react-big-calendar .rbc-event-content {
  font-size: 0.85em;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  backdrop-filter: blur(5px);
}
