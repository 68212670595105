.dashboard {
  padding: 1.5rem;
}

.stat-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, var(--card-color), transparent);
  opacity: 0.2;
  z-index: 0;
}

.stat-card > * {
  position: relative;
  z-index: 1;
}

.stat-card h4 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
}

.stat-card p {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

.section {
  background: linear-gradient(to bottom right, #E0E7FF, #C7D2FE);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.section h3 svg {
  margin-right: 0.5rem;
}

.input-group {
  display: flex;
  margin-bottom: 1rem;
}

.input-group input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #CBD5E0;
  border-radius: 0.25rem 0 0 0.25rem;
}

.input-group button {
  padding: 0.5rem;
  background-color: #4A5568;
  color: white;
  border: none;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions button, .actions a {
  padding: 0.5rem 1rem;
  background-color: #4299E1;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.actions button svg, .actions a svg {
  margin-right: 0.5rem;
}

.error-message {
  background-color: #FED7D7;
  color: #9B2C2C;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .stat-cards {
    grid-template-columns: 1fr;
  }
}
